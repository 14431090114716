@import '~@mtb/ui/stylesheets/main.scss';

.api-keys-content {
  .keyName {
    height: 19px;
  }

  .keyName::placeholder {
    font-style: italic;
    color: gray;
  }

  .createKey {
    padding: 8px;
    margin-left: -10px;
    &:hover {
      background-color: #eee;
    }
  }

  .addKeyButton {
    color: #000;
    border: 1px solid #30373a;
    width: 8em;
    order: 0;
    margin-left: 1em;
    &[disabled] {
      border: 1px solid #8d8d8d;
      background-color: #8d8d8d;
      color: #fff;
    }
  }
}
