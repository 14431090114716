@import 'variables';

.model-card-outer {
  .icon-container {
    padding: 0 0.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: inherit;
    height: 100%;

    svg {
      width: 2.75em;
      height: 2.75em;
      overflow: visible;
    }
  }

  .model-card-loading {
    display: flex;
    align-items: center;
    min-height: 5em;
    width: 100%;

    .icon-container {
      justify-content: center;

      div {
        min-width: 12.5%;
        max-width: 12.5%;
        padding: 0;
      }
    }

    .model-card-loading-text {
      display: flex;
      flex-direction: column;
      width: 75%;
      white-space: nowrap;

      label {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .name {
        font-weight: $faux-bold-font-weight;
        font-size: 12px;
      }

      .note {
        font-size: 10px;
      }
    }
  }

  .model-card-container {
    position: relative;
    overflow: visible;

    &:hover {
      .model-text,
      .icon-container {
        background-color: #f3f5f7;
        border-radius: 3px;
      }
    }

    &:not(:hover) .context-icon {
      display: none;
    }

    .model-card {
      display: flex;
      align-items: center;
      min-height: 5em;

      .model-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: inherit;
        width: 70%;
        white-space: nowrap;

        label {
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .type {
          font-size: 10px;
        }

        .name {
          font-size: 16px;
          font-weight: 600;
        }
      }

      .role-and-status-icon {
        margin-right: 5px;
        width: 2.75em;
        height: 2.75em;
      }

      .context-icon {
        width: 1.5em;
        height: 1.5em;
      }
    }
  }
}
