@import 'variables';

.dataDisplay {
  color: #a13a3a;
  font-family: 'Courier New', Courier, monospace;
  line-height: 1.5em;
}

.detailsExpander {
  font-size: 1.25em;
  fill: #121212;
  cursor: pointer;
  display: inline-block;
  transition: all linear 0.1s;
  transform: rotate(-90deg);
  width: 24px;
  height: 24px;

  &.open {
    transform: rotate(-45deg);
  }
}

.iconCell {
  display: flex;
  align-items: center;
}

.auditGridIcon {
  height: 16px;
  padding-right: 0.5em;
  min-height: 16px;
  min-width: 14px;
}

.audit-log {
  margin: 2em 0.75em 0 0.75em;

  .audit-log-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1em;

    .audit-log-filter {
      display: flex;
      align-items: center;
    }
  }

  .auditLogTable {
    .header-row {
      border-bottom: unset;

      div {
        span {
          font-size: 13px;
          font-weight: $faux-bold-font-weight;
          color: $btn-secondary-text-color;
        }
      }
    }

    .data-row {
      border: 1px solid #eee;
      border-bottom: 0;
      border-radius: 3px;
    }

    .data-row:has(+ .data-details-row) {
      border-bottom: 1px solid #eee;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .data-row:not(:has(+ .data-row)) {
      border-bottom: 1px solid #eee;
    }

    .data-details-row {
      background-color: #fafafa;
      margin: unset;
      border: 1px solid #eee;
      border-bottom: 0;
      border-top: 0;
      border-radius: 0;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
      height: min-content;
      max-height: 15em;

      .dataDisplay {
        color: $btn-secondary-text-color;
        user-select: text;
      }
    }

    .data-details-row:not(:has(+ .data-row)) {
      border-bottom: 1px solid #eee;
    }
  }

  .refresh-button {
    cursor: pointer;
    margin-left: 0.5em;
    display: inline-flex;
    align-items: center;
    border-radius: 4px;
    padding: 0.75em;
    height: fit-content;

    svg {
      width: 18px;
      height: 18px;
      margin-right: 0.5em;
    }

    &:hover {
      background: #eee;
    }
  }
}
