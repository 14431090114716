.models-content {
  .models-side-panel {
    min-width: 300px;
    margin-right: 15px;

    .model-cards-container {
      margin: 1em 0;
    }

    .add-model {
      display: flex;
      align-items: center;
      font-size: 13px;
      font-weight: 600;
      width: max-content;

      .add-icon {
        padding: 0.1em;
        margin-right: 5px;
        width: 1.75em;
        height: 1.75em;
      }
    }
  }

  .table-wrapper {
    display: flex;
    flex-direction: column;
  }
}

.sortable-label {
  padding: 0 !important;
  .mtb-ui-table-sort-label-icon {
    width: 0.6em;
  }
}
