@import 'variables';

.context-menu {
  position: absolute;
  width: 180px;
  min-height: auto;
  right: -10px;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: left;
  background-color: #fff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  flex-direction: column;
  padding: 5px;

  .mtb-ui-click-away-listener {
    padding: 0;
    width: 100%;
  }

  div {
    cursor: pointer;
    padding: 5px 10px;
    color: $default-text-color;

    &[disabled] {
      cursor: default;
      color: #ccc;
    }

    &:hover:not([disabled]) {
      background-color: #eee;
    }
  }
}
