@import '~@mtb/ui/stylesheets/main.scss';
@import 'variables';

$key-entry-height: 4.9em;
$section-spacing: 3vw;
$access-control-size: 25vw;
$sample-code-size: calc(100vw - #{$access-control-size} - #{$section-spacing});

.integration-content,
.models-content {
  padding: 10px;
  display: flex;
  gap: $section-spacing;

  .mtb-ui-input-base-focused {
    outline: none !important;
  }

  Button.colored {
    pointer-events: auto;
    background-color: #5192ed;
    min-width: 130px;
    padding: 0 16px;
    margin: 20px 0;

    @media (max-width: 540px) {
      white-space: normal;
      padding: 0 4px;
    }
  }

  .buttons {
    .dialog-submit {
      background-color: #5192ed;
      padding: 4px;
      margin: 0 12px;
    }

    .dialog-cancel {
      background-color: #dddddd;
      color: #535455;
    }
  }

  .mtb-ui-button-root {
    font-weight: 400;
  }

  h4 {
    font-size: 1.2em;
    font-weight: 600;
    margin: 15px 0;
    color: #555;
  }

  .access-control {
    min-width: min-content;
    max-width: $access-control-size;

    .description {
      margin-bottom: 20px;
    }

    .urlLinks {
      margin: 30px 0;

      .url {
        padding: 8px 0;

        a {
          overflow-wrap: break-word;
        }

        h5 {
          font-size: 0.95em;
          font-weight: 500;
        }
      }
    }

    .status > svg {
      margin-right: 5px;
    }
  }

  .table-container {
    & div {
      position: relative;
      overflow: visible;
    }

    .row-container {
      position: relative;
      padding: 0;
      overflow: visible;
    }

    .context-menu {
      padding: 5px;
      right: 1px;
      bottom: -20px;
      position: absolute;

      div {
        padding: 5px 10px;
      }
    }

    .flex-table {
      &.row {
        height: 4.9em;

        &:hover > .flex-cell .context-menu-icon {
          visibility: visible;
        }
      }

      .flex-cell {
        div {
          padding: 4px 0;
        }

        .relative {
          position: relative;
          height: 100%;
          width: 100%;
        }

        .key-name {
          overflow: hidden;
          font-size: 15px;
          font-weight: 600;
        }

        .sub-text {
          overflow: hidden;
          font-size: 9px;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
        }

        .context-menu-icon {
          visibility: hidden;
          cursor: pointer;

          svg {
            vertical-align: middle;
            padding: 0;
            width: 1.5em;
            height: 1.5em;
          }
        }
      }
    }
  }

  .sample-code {
    min-width: $sample-code-size;
    max-width: $sample-code-size;

    .description {
      max-width: 90%;
    }

    .request {
      display: flex;
      flex-direction: column;
      gap: 30px;

      .request-content {
        display: flex;
        flex-direction: row;
        gap: 30px;

        .form,
        .curl {
          margin-top: 15px;
          display: flex;
          flex-direction: column;
          gap: 15px;
          width: 50%;
          min-width: 350px;

          .options {
            margin: 15px 0 0 5px;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;

            .select {
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 10px;

              label {
                margin-top: 5px;
              }
            }

            .mtb-ui-form-group {
              flex-wrap: nowrap;
            }

            .mtb-ui-checkbox {
              margin-left: 20px;
            }

            .mtb-ui-button-base-root {
              padding: 8px;
              margin-left: 6px;
            }
          }

          .values {
            font-size: 13px;
            flex-grow: 1;
            min-height: 25vh;
            max-height: 50vh;
            padding: 20px;
            background-color: $table-cell-background-color;

            .table-form-multi {
              margin-bottom: auto;

              &.stability {
                overflow: hidden;

                tr td {
                  width: 50%;

                  .mtb-ui-input-base {
                    width: 100%;
                  }
                }
              }

              th {
                font-weight: 400;
                font-family: inherit;
              }

              th.mtb-ui-table-cell-root {
                padding: 0 15px 10px 0;
              }

              td.mtb-ui-table-cell-root {
                padding: 1px;
                border: 1px solid $table-cell-background-color;
                background-color: #fff;

                &.text-input-cell:hover {
                  border-color: #616161;
                }

                &.text-input-cell.error {
                  border-color: $error-text-color;
                }
              }

              .mtb-ui-input-base {
                width: 12.5em;
                min-width: 100%;
                border: none;
                font: inherit;
              }
            }

            .table-form-single {
              tr td {
                border-bottom: unset;
              }

              tr td:last-child {
                .mtb-ui-input-base {
                  width: 80%;
                  min-width: 80%;

                  &:not(.mtb-ui-input-base-full-width) {
                    min-width: 135px;
                    max-width: 135px;
                  }
                }
              }
            }

            .stability tr td:last-child .select-inner-input {
              width: calc(80% - 15px);
            }

            .tr-divider {
              height: 20px;
              background-color: inherit;
            }

            .browse {
              width: 100%;
              height: fit-content;

              .browseButton {
                min-width: auto;
                width: auto;
                padding: 5px 10px;
              }
            }
          }

          .mtb-ui-table-row {
            background-color: $table-cell-background-color;
          }

          .mtb-ui-table-container-root {
            th {
              border: none;
            }
          }

          .mtb-ui-table-root {
            height: max-content;
          }
        }
      }

      .submit {
        span {
          font-weight: 600;
          display: block;
          margin-top: 25px;
        }

        .errorDetails {
          color: $error-text-color;
          margin-top: 20px;
        }

        .results {
          margin-top: 10px;
          padding: 20px;
          background-color: $table-cell-background-color;
        }
      }

      textarea {
        height: 100%;
        width: -webkit-fill-available;
        background-color: $table-cell-background-color;
        border: none;
        outline: none;
        overflow: auto;
      }
    }

    .mtb-ui-input-base {
      border: 1px solid #e0e0e0;
      background-color: #fff;
      font-size: 13px;
      &.mtb-ui-input-base-error {
        border: 1px solid $error-text-color;
      }
    }
  }
}
