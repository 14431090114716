.overview-container,
.service-metrics {
  h3 {
    font-size: 16px !important;
    margin-top: 14px;
  }

  .mtb-ui-select {
    border-color: #ddd;
    width: 180px;
  }

  .detailsArea .formControl {
    input[type='text'][readonly] {
      border: 0 solid transparent;
      padding: 0;
    }
  }

  .title-area {
    display: flex;
    flex-direction: column;
    font-size: 1.2em;
    padding: 25px 10px;

    h4 {
      font-size: 0.875em;
      font-weight: bold;
    }

    p {
      font-size: 0.875em;
      color: #aaa;
    }
  }

  .detailsArea,
  .endpoint {
    display: flex;
    flex-direction: row;
    padding: 0 10px;
    flex-wrap: wrap;

    .formControl {
      margin-top: 1em;
      margin-bottom: 1em;
      white-space: nowrap;
      min-height: 2.5em;
      line-height: 2.25em;

      label {
        display: inline-block;
        width: 17em;
      }

      input[type='text'],
      select {
        border: 1px solid #e0e0e0;
        border-radius: 5px;
        width: 20em;

        &.italic {
          font-style: italic;
        }
      }
    }

    .formGroup {
      margin-right: 5em;

      &:last-child {
        margin-right: 0;
      }
    }

    .link {
      color: #4c8fdd;
      text-decoration: underline;
    }
  }

  .endpoint {
    display: block;
  }

  .metricsStatusMessage {
    padding: 1em;
    font-style: italic;
  }

  .metric-controls {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    padding: 1em 1em;

    label {
      font-size: 0.875em;
      padding-right: 1em;
    }

    .mtb-ui-select {
      font-size: 0.875em !important;
      min-width: 10em;
      margin-right: 1em;
    }
  }

  .metric-cards {
    display: flex;
    flex-direction: row;
    padding: 1em;
    flex-wrap: wrap;
  }

  .metric-card {
    width: 30em;
    min-width: 30em;
    margin-right: 1em;
    margin-bottom: 1em;
    padding: 1em;
    overflow: hidden;

    &:not(.borderless) {
      border-radius: 5px;
      box-shadow: 0 0 5px #ccc;
    }

    h4 {
      font-size: 1em;
      margin-bottom: 2em;
      margin-right: 1em;
      display: inline-block;
    }

    p {
      font-size: 0.875em;
      display: inline-block;
    }
  }

  .bigNumberCentered {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    span {
      font-size: 5em;
      display: block;
    }

    span.unit {
      font-size: 2em;
      color: #555;
    }
  }
}