@import 'variables';

.schema-table {
  padding: 0 0.75em 0 1.35em;

  h4 {
    font-size: 1.2em;
    font-weight: 600;
    margin: 15px 0;
    color: #555;
  }

  .model-variable-table {
    table-layout: fixed;
  }
}

.dashboard-header {
  color: $default-text-color;
  display: flex;
  flex-direction: column;
  padding: 0.5em;
  min-width: max-content;

  .dashboard-label {
    padding-left: 0.75rem;
  }

  .link {
    cursor: pointer;
    color: #1976d2;
  }

  .overview {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title-container {
      display: flex;
      align-items: baseline;

      input.title {
        margin-top: 0.15rem;
        margin-bottom: 0.15rem;
        padding-top: 0.1rem;
        padding-bottom: 0.1rem;
      }

      .title {
        border: unset;
        padding: 0.25rem 0.75rem;
        font-size: 24px;
        font-weight: bold;

        &.editing {
          border-radius: 3px;
          outline: 2px solid #7da6d8;
        }

        &.hidden {
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1000;
          visibility: hidden;
        }
      }

      .edit-button {
        color: $btn-primary-color;
        font-size: 13px;
      }
    }

    .deployment-status {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 1em;

      .deployment-status-message {
        font-size: 16px;
        margin: 0 1.75em 0 0.25em;
      }

      .deployment-loading-message {
        font-size: 16px;
        margin: 0 0 0 1em;
      }

      .warning-icon {
        height: 2em;
        width: 2em;
      }

      .deployment-loading-icon {
        background-color: unset;
        padding: unset;

        .loading-spinner {
          height: 3em;
          width: 3em;
        }
      }

      .deployment-control {
        font-size: 14px;
        border-radius: 3px;
        width: 10rem;
        border: unset;
        padding: 0.75rem 0;

        &.activate {
          background-color: $btn-primary-color;
          color: $btn-enabled-text-color;
        }
        &.pause {
          background-color: $btn-disabled-color;
        }
        &.reactivate {
          background-color: #d52525;
          color: $btn-enabled-text-color;
        }
      }
    }
  }
  .model-information {
    padding: 0 0.75rem;
  }

  .loading-status {
    label {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .inline {
      padding: 0;
    }

    .model-loading-title {
      font-size: 1.25em;
      font-style: italic;
    }
  }

  .container-bottom-right {
    margin-left: auto;
    margin-top: auto;
  }

  .deployment-information {
    display: flex;
    white-space: nowrap;

    .info {
      padding: 0 0.75rem 0.5rem 0.75rem;
      margin-right: 1%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 13px;

      .champion-icon {
        margin: 0 0.5em;
        width: 1.5em;
        height: 1.5em;
      }

      .model-name {
        padding-left: 0.25em;
      }
    }
  }
}
