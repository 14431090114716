.loading-wrapper {
  padding-top: 11.5em;
  left: 5em;
  right: 1.5em;
  position: absolute;
  z-index: 9999;
  height: 100%;
  justify-content: center;
  display: flex;
  background-color: white;

  .loading-spinner {
    width: 3.5em;
    height: 3.5em;
  }

  > div {
    display: flex;
    align-items: center;
    flex-direction: column;

    .caption {
      color: #5f5f5f;
      font-size: 1.1rem;
      line-height: 1.75em;
      font-style: italic;
    }
  }

  &.inline {
    position: relative;
    justify-content: flex-start;
    padding: 1.25em;
    left: 0;
    right: 0;
    z-index: 0;
  }

  &.centered {
    padding: 0;
    margin: 0;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    align-items: center;
  }

  &.small-icon > div > svg {
    width: 24px;
  }
}
