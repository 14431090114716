.gridPager {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    width: 20em;
    margin-top: 1em;

    .incrementPage { 
        fill: #121212;
        cursor: pointer; 
        height: 24px;
        width: 24px;
        display: flex;
        align-items: center;

        &.pageRight {
            transform: rotate(-90deg);
        }

        &.pageLeft {
            transform: rotate(90deg);
        }

        &.firstPage {
            transform: scaleX(-1);
        }

        &.disabled {
            cursor: default;

            svg {
                fill: #ccc;
            }
        }
    }

    .status {
        font-size: .875em;
        color: #555;
        text-align: center;
        min-width: 8em;
        font-weight: bold;
    }
}

.chevronPager {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .entries-text {
      font-size: 13px;
      color: #666666;
      margin-right: 1em;
    }

    .icon-disabled {
      opacity: 0.25;
    }
  }