@import '~@mtb/ui/stylesheets/main.scss';
@import './components/variables.scss';

html,
body {
  user-select: none;

  ::-webkit-scrollbar {
    height: 8px;
    width: 8px;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 50px;
  }

  ::-webkit-scrollbar-thumb {
    background: #b7b7b7;
    border-radius: 50px;
  }
}

.selectable {
  user-select: all;
}

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.alert {
  margin-top: 2em;
  color: #c95000;
  display: inline-flex;

  strong {
    margin-left: 0.5em;
    margin-right: 0.5em;
  }

  svg {
    width: 18px;
    height: 18px;
    margin-right: 0.25em;
  }
}
.alert.noMargin {
  margin-top: 0;
}

.marginTop {
  margin-top: 1em;
}

.mtb-ui-select {
  flex-grow: 1;
  max-width: 15em;
}

.mtb-ui-menu .mtb-ui-menu-item {
  justify-content: flex-start;
  font-weight: 400;
  padding: 5px 14px;
}

.mtb-ui-table-cell-root,
.mtb-ui-button-base-root {
  font-size: inherit !important;
}

.mtb-ui-modal {
  z-index: 10001;

  .mtb-ui-popover-surface > .mtb-ui-list-root {
    max-height: 350px;
  }
}

.mtb-ui-tooltip-popper > .mtb-ui-tooltip {
  font-size: 12px;
  padding: 12px 14px;
  background-color: $default-text-color;

  span {
    font-weight: 600;
  }

  .mtb-ui-tooltip-arrow {
    display: none;
  }
}

.content {
  display: flex;
  flex-grow: 1;
  height: 100%;
  overflow: hidden;

  .leftMenu {
    width: 56px;
    min-width: 56px;
    text-align: center;
    padding: 1em 0;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #e0e0e0;

    svg {
      cursor: pointer;
      width: 24px;
      height: 24px;
      padding: 8px;
      border-radius: 4px;
    }

    a.active svg {
      fill: $active-color;
      background-color: rgba(76, 143, 221, 0.08);
    }

    // Below is the mouse-down styling
    a:active:hover svg {
      fill: $active-color;
      background-color: rgba(76, 143, 221, 0.16);
    }

    svg:hover {
      fill: $active-color;
      background-color: rgba(76, 143, 221, 0.08);
    }
  }

  .main {
    padding: 1em;
    flex-grow: 1;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;

    h3 {
      font-weight: 500;
      font-size: 1.53em;
      padding: 0.5em 1em;
      color: $default-text-color;
    }

    .modalDialog,
    .uploadDialog {
      h3 {
        padding: 0.5em 0;
      }
    }

    .navigation-header {
      display: flex;
      width: 100%;
      align-items: center;

      .navigate-back-btn {
        padding-left: 1em;
        cursor: pointer;
      }

      .navigation-title {
        width: 100%;
        height: 1.35em;
      }

      h3 {
        border-bottom: none;
      }
    }

    .divider {
      border-bottom: 1px solid #c0c0c0;
    }

    .toolbar {
      border-top: 1px solid #eee;
      padding: 1em 1em;
      margin-bottom: 1em;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #eee;

      input {
        margin: 0 10px;
        border: 1px solid #c0c0c0;
        border-radius: 5px;
      }

      p {
        display: flex;
        align-items: center;
      }

      button {
        min-height: 2.2em;
      }

      div:not(:is(.mtb-ui-select, .mtb-ui-select-base)) {
        cursor: pointer;
        margin-right: 0.5em;
        display: inline-flex;
        align-items: center;
        border-radius: 4px;
        padding: 0.75em;
        height: fit-content;

        i {
          width: 16px;
          height: 16px;
          margin-right: 0.5em;
        }

        svg:not(.mtb-ui-select-base-icon) {
          width: 18px;
          height: 18px;
          margin-right: 0.5em;

          &.noPad {
            padding: 0;
            margin: 0;
          }
        }

        &.noPad {
          padding: 0;
          margin: 0;
        }

        &.gridStats {
          margin-right: 0;
          flex-grow: 1;
          justify-content: right;
          font-size: 0.9em;
          b {
            font-weight: 500;
            margin-right: 5px;
          }
        }

        &.noClick {
          cursor: default;
          padding: 0em 0.75em;

          span {
            display: inline-block;
            margin-right: 1em;
          }
        }
        &:hover:not(.gridStats):not(.noHover):not(.noClick) {
          background: #eee;
        }
      }

      &.noPad {
        border-bottom: none;
        margin-bottom: 0px;
      }

      .rightSideText {
        cursor: default;
        flex-grow: 1;
        text-align: right;
        font-size: 0.875em;
        line-height: 1.75em;
        margin-right: 0;

        p {
          margin-right: 0;
        }
      }
    }
  }
}

.tab-navigation {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: auto;
  min-width: fit-content;
  padding: 0.5em;
  color: #555;

  ul.nav {
    display: flex;
    align-items: left;
    border-bottom: 1px solid #eee;
    font-weight: 500;
    font-size: 1.2em;
    min-width: max-content;
  }

  ul.nav li {
    padding: 0.75rem;
    margin-right: 1em;
    margin-bottom: -1px;
    list-style: none;
    cursor: pointer;

    &:hover {
      background: #f5f5f5;
    }

    &.active {
      border-bottom: 3px solid $active-color;
    }

    &.obsolete {
      color: #ccc;
    }
  }

  .tab-contents {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}

.upload-report-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;

  .upload-icon {
    height: 300px;
    width: 300px;
  }

  .upload-text {
    text-align: center;
    color: #999;

    &.description {
      max-width: 430px;
    }

    &.instructions {
      width: 480px;
    }
  }

  .upload-button {
    cursor: pointer;
    margin-top: 20px;
    padding: 0.75rem;
    color: white;
    background-color: #1c7ec7;
  }
}

.upload-banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: auto;
  font-size: 0.875em;
  background-color: #fafac5;
  padding: 0.75em 1.75em;

  .banner-text {
    display: flex;
    flex-direction: column;
    padding-bottom: 0.5em;

    .icon {
      width: 1.2em;
      padding: 0.2em 0.2em 0 0;
    }

    label {
      display: flex;
      align-items: center;
      font-weight: bold;
    }
  }

  .upload-button {
    cursor: pointer;
    min-width: 110px;
    text-align: center;
    align-self: center;
    background-color: white;
    padding: 0.5em;
    margin-left: 0.75em;
    border: 1px solid #888;
    border-radius: 3px;
  }
}

.report-header-container {
  display: flex;
  width: 100% !important;
  justify-content: space-between;

  .flex-header {
    align-items: center;
  }
}

.flex-header {
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  .flex-right {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    align-items: center;

    &.legacy-stability {
      height: 20px;

      .lastUpdateText {
        font-size: 0.9em;
        padding-top: 1.5em;
      }
    }
  }

  .flex-right > div {
    margin-left: 10px;
    margin-right: 10px;
  }

  .replace-data-button {
    display: flex;
    cursor: pointer;
    font-size: 0.875em;
    font-weight: bold;
    margin-top: 20px;
    align-items: center;

    .icon {
      width: 12px;
      height: 12px;
      margin-right: 5px;
    }
  }

  .create-pdf-button {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: #eee;
    border: 0;
    border-radius: 3px;
    font-size: 0.875em;
    font-weight: bold;
    padding: 0.5em 1em !important;
    cursor: pointer;
    text-align: center;
    width: 85px !important;
    margin-top: 20px;

    .pdf-icon {
      height: 16px;
      width: 16px;
      padding-right: 3px;
    }
  }
}

.period {
  display: flex;
  align-items: center;

  label {
    font-weight: bold;
    padding-right: 10px;
    font-size: 12px;
  }

  .period-select {
    min-width: 12em;
    cursor: pointer;
    border: 1px solid $btn-border-color;
    border-radius: 5px;
    font-size: 12px;
  }
}

.report-dashboard-container {
  label {
    font-weight: bold;
  }

  .expanded-view,
  .stability-report {
    max-width: calc(100vw - 150px);
  }

  .production-data {
    display: flex;
    flex-direction: column;
    font-size: 1.2em;
    padding: 25px 10px;
    width: 100%;

    .period-label {
      font-size: 12px;
      color: #888;
      font-weight: 600;
      margin-bottom: 6px;
    }

    .period-select {
      margin-bottom: 10px;
    }

    .date-navigation {
      display: flex;
      align-items: center;

      .nav-buttons {
        display: flex;
        padding-left: 1em;
        margin-top: -5px;

        .nav-icon {
          width: 15px;
          margin: 0.5em;
          cursor: pointer;
        }

        .disabled {
          pointer-events: none;
          color: #ccc;
        }
      }
    }

    .nav-pills-container {
      display: flex;
      margin: 0.5em auto 1.5em auto;

      .nav-button {
        width: 32px;
        flex-shrink: 0;
        margin: 5px auto 0 auto;

        .icon-disabled {
          opacity: 0.25;
        }
      }

      .nav-pills {
        display: flex;
        font-size: 8.75pt;
        min-width: 85vw;
        max-width: 85vw;
        justify-content: center;
        align-items: center;

        .icon {
          min-width: 20px;
          width: 20px;
          height: 20px;
          margin: 5px 5px 0 5px;
        }

        .mtb-ui-button-root {
          min-width: fit-content;
          padding: 10px;
        }

        button {
          height: 32px;
          margin: 0 0.4em;
          background-color: #fff;
          color: #1f1f1f;
          border: 1px solid #c4cbd4;
          border-radius: 100px;
          font-weight: normal;
          transition: all 0.2s ease;

          &:focus {
            outline-color: #888;
          }

          &.active {
            font-weight: 600;
            border-color: #1e88e5;
            padding: 0 0.94em;
          }
        }
      }
    }

    .champion-bin-label {
      font-size: 12px;
      margin-left: 30px;

      span {
        font-weight: 600;
      }
    }
  }

  .drift-champion {
    display: flex;
    padding: 0 0 10px 10px;

    .icon {
      margin-right: 10px;
      width: 40px;
      min-width: 40px;
      height: 40px;
    }

    .name-and-type {
      display: flex;
      flex-direction: column;

      .name,
      .type {
        max-width: 230px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .name {
        font-size: 1.1em;
        font-weight: 500;
      }

      .type {
        font-size: 0.9em;
        font-weight: normal;
        color: #888;
      }
    }
  }

  .stability-select {
    padding: 24px 10px;
    font-size: 0.875em;

    label {
      margin-right: 10px;
    }

    select {
      border: 1px solid #efefef;
      border-radius: 5px;
    }
  }

  .no-data-message {
    padding: 0 10px;
    color: #555;
  }

  .expand-icon {
    height: 1.4em;
    width: 1.4em;
    margin-top: 5px;
  }

  .response-table,
  .predictor-table {
    margin-bottom: 50px;

    .header-row,
    .data-row {
      color: #555;
      height: 3.5em;
      > div {
        margin-left: 1em;
      }
    }

    .data-row:hover {
      cursor: pointer;
      box-shadow: none;

      label {
        cursor: pointer;
      }
    }

    div.status {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.9em;
    }

    div.error-state {
      font-size: 0.9em;
      color: #999;
    }

    .icon-container {
      width: 24px;
      margin: 5px 0 0 5px;
      text-overflow: clip;

      .statusIcon {
        width: 1.5em;
      }
    }

    .collapsed-graph {
      margin-left: -25px;

      .highcharts-container {
        height: 45px !important;
        margin-bottom: 1px;

        .highcharts-grid-line {
          display: none;
        }
      }
    }

    .expanded-view {
      padding: 20px 0 20px 20px;
      border-bottom: 2px solid #eee;

      .graph-container {
        display: flex;
        flex-direction: row;

        .chart-content {
          width: 48%;
          padding: 10px;
        }

        .stats-table {
          display: flex;
          flex-direction: column;
          break-before: avoid-page;

          .header-row {
            display: flex;
            border-bottom: solid 1px #eee;
            height: 1.5em;

            div {
              text-overflow: ellipsis;
              margin-left: 0;
            }
          }

          .data-row {
            display: flex;
            font-size: 0.875em;
            border-bottom: none;
            cursor: default;
            height: 1.5em;

            div {
              text-overflow: ellipsis;
              margin-left: 0;
            }
          }
        }
      }
    }
  }

  .validationMessage {
    padding-left: 10px;
    margin-top: 5px;
  }
}

.performance-container {
  #stability-report .no-data-message {
    padding: 15px 0px;
  }
}

.lastUpdateText {
  font-size: 0.75em;
  padding-left: 1em;
  color: #999;
}

.stability {
  .stats-summary {
    display: flex;
    padding: 0 0 10px 10px;
    overflow-x: auto;

    .stats-cell {
      display: flex;
      flex-direction: column;
      font-weight: bold;
      margin-right: 30px;

      label {
        display: inline-block;
        min-width: 50px;
        font-size: 0.875em;
        color: #888;
      }

      span {
        font-size: 1.15em;
      }
    }
  }

  .stability-plot {
    padding-top: 30px;
  }

  .lastUpdateText {
    display: block;
    font-size: 0.9em;
    padding-bottom: 1.5em;
  }

  ::-webkit-scrollbar {
    height: 8px;
    background: #eee;
    border-radius: 20px;
    width: 8px;
    transition: width 2s;
  }

  ::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 50px;
  }

  &.no-data {
    color: #555 !important;
    margin: 20px 0 0 10px;
  }
}

.expandable-header {
  padding: 15px 0 0 5px;

  .header-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    font-size: 1.1em;
    font-weight: bold;
    color: #000;
    height: 0.3em;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;

    .expand-icon {
      height: 1.1em;
      width: 1.1em;
      margin: 2px 5px 0 0;
    }

    &:hover {
      color: #568ccb;
    }
  }
}

.required-predictor-variable-checkbox {
  margin-left: 17px;
}

.clickable {
  cursor: pointer !important;
}

.not-clickable {
  cursor: default !important;
}

.system-audit-log-header {
  border-bottom: 1px solid #eee;
}

.ellipses {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.mtb-ui-input-base-focused.mtb-ui-select {
  outline: none !important;
}

.modeler-select-field {
  width: 12.5em;
  border: 1px solid $btn-border-color !important;
  border-radius: 0;

  &:hover {
    border: 1px solid $btn-border-color !important;
  }
}
