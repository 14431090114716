@import 'variables';
$cell-padding: 0.5em;

.table-container {
  display: block;
  max-width: 100%;

  div,
  span {
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 5px;
  }

  .flex-table.row {
    display: flex;
    height: 3.5em;
    min-height: 3.5em;

    &:hover {
      background-color: $table-cell-background-color;

      .actionLink {
        visibility: visible;
      }

      .pending > div {
        background-color: $table-cell-background-color;
      }
    }
  }

  .flex-header {
    display: flex;
    flex-direction: row;
    height: 3em;
    line-height: 2em;
    color: $btn-secondary-text-color;
    font-weight: bold;
    border-bottom: 1px solid #eee;

    div > span {
      font-size: 13px;
      font-weight: $faux-bold-font-weight;
    }

    .icons {
      border: none;
    }
  }

  .flex-row {
    width: calc(100% / 4);
    padding: 0.5em 0.5em;
    border-bottom: 1px solid #eee;
  }

  .flex-cell {
    display: inline-flex;
    width: calc((100% / 3) - (#{$cell-padding} * 2));
    max-width: calc((100% / 3) - (#{$cell-padding} * 2));
    text-align: left;
    align-items: center;
    padding: $cell-padding;
    flex-direction: row;
    border-bottom: 1px solid #eee;
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;

    .actionLinkItem {
      padding: 0 0.2em;
    }

    > svg {
      min-height: 1.25em;
      max-height: 1.25em;
      min-width: 1.25em;
      max-width: 1.25em;
      margin: 0 0.25em;
    }

    .status {
      flex-grow: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 100%;
      width: 100%;

      div {
        overflow: hidden;
      }

      .ready {
        padding: 0;
      }

      > svg {
        min-width: 1em;
        max-width: 1em;
        min-height: 1em;
        max-height: 1em;
        margin-right: 0.25em;

        &.active,
        &.deployed {
          fill: $active-color;
        }
        &.expired,
        &.failed,
        &.deleting {
          fill: #d52525;
        }
        &.revoked,
        &.creating,
        &.destroying,
        &.pending,
        &.queued {
          fill: #ffc107;
        }
        &.published {
          fill: #e0e0e0;
        }
      }
    }
  }

  .top-border > .flex-cell {
    border-top: 1px solid #eee;
  }

  .icons {
    display: flex;
    justify-content: flex-end;
    font-style: italic;
  }

  .driftCell > svg {
    height: 1.2em;
    padding: 0;
  }
}
