@import '~@mtb/ui/stylesheets/main.scss';
@import 'variables';

.dialogContainer {
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: $dialog-z-index;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(51, 51, 51, 0.4);

  .modelBusy {
    @extend .dialog;
    box-shadow: unset;
    padding: 2em 1.5em;
    height: auto;
    width: 560px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h3 {
      padding: 0 !important;
      margin: unset;
    }

    p {
      margin: 0.5em;
    }

    .loading-wrapper {
      margin: 0;
      div {
        margin-left: 0;
      }
    }
  }

  .renameModel {
    display: flex;
    flex-direction: column;

    p {
      margin-bottom: 0.75em;
    }

    input {
      font-size: 14px;
      flex-grow: 1;
      margin-left: 10px;
      margin-bottom: 0.75em;
      padding: 7px 10px;
    }
  }
  .importBaselineData {
    .browse {
      margin-left: 0;
    }
  }
  .downloadData {
    .download-type-container {
      margin-left: 10px;

      .mtb-ui-form-label,
      .mtb-ui-form-control-label-text {
        font-size: 13px;
      }

      .mtb-ui-form-label {
        margin-bottom: 0;
      }

      .hidden-download {
        display: none;
      }
    }

    .mtb-ui-radio-root {
      padding: 0.25em;
    }
  }
  .modelInformation {
    p {
      margin-bottom: 0.75em;
    }
  }
}

.mtb-ui-autocomplete-popper {
  z-index: ($dialog-z-index + 1);
  overflow-wrap: break-word;
  word-break: break-all;
}

.MuiPickersPopper-root.MuiPopper-root {
  z-index: ($dialog-z-index + 1);
}

.dialogButtons {
  right: 1.5em;
  display: flex;
  flex-direction: row-reverse;
  justify-items: right;
  padding-top: 20px;
  gap: 10px;

  .cancelButton,
  .confirmButton,
  .copyButton {
    border: 1px solid $btn-border-color;
    border-radius: 3px;
    font-size: 14px;
    height: 2.6em;
  }

  .cancelButton,
  .copyButton {
    min-width: 90px;
  }

  .confirmButton {
    min-width: 110px;
  }

  .cancelButton {
    color: $btn-secondary-text-color;
    background-color: $btn-secondary-color;
    order: 1;
  }

  .confirmButton,
  .copyButton {
    color: $btn-enabled-text-color;
    background-color: $btn-primary-color;
    order: 0;
    &[disabled] {
      background-color: $btn-disabled-color;
      color: $btn-disabled-text-color;
    }
  }
}

.dialogContainer .modalDialog .mtb-ui-input.mtb-ui-input-base.mtb-ui-input-base-focused {
  outline: 2px solid black; // Match default focus

  &.mtb-ui-select,
  &.mtb-ui-autocomplete-input-root {
    outline: none;
  }
}

.dialog {
  position: relative;
  margin: auto;
  left: auto;
  top: 5%;
  bottom: 5%;
  box-shadow: 0 0 10px #888;
  border-radius: 5px;
  background-color: #fff;
  color: #555;
  padding: 1.5em;
  overflow: hidden;
  z-index: 1000;

  small {
    height: 200px;
  }

  h3,
  p,
  div {
    margin-left: 10px;
  }

  p {
    margin-bottom: 25px;
  }

  .dialogContent {
    margin-left: 10px;
    margin-bottom: 25px;

    p {
      margin: 0;
    }

    ul {
      margin-top: 10px;
      margin-left: 20px;
      list-style-position: outside;

      li {
        margin-bottom: 5px;
      }
    }
  }

  button {
    cursor: pointer;
    font-weight: normal;
    height: 40px;
  }

  h3 {
    font-size: 16px !important;
    font-weight: bold;
    border-bottom: none !important;
    margin-bottom: 10px;
  }

  input[type='file'] {
    border: none;
  }

  a {
    color: $link-text-color;
  }

  .instructions {
    font-size: 14px;
    margin-bottom: 20px;
  }

  .formControl {
    font-size: 1em;
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5em;

    &.row {
      flex-direction: row;
      align-items: center;

      label,
      .mtb-input-base {
        width: 20em;
      }

      .mtb-ui-input-base {
        font-size: unset;
      }

      .LocalizedInput {
        border: none;
      }
    }

    .inputWithText {
      display: inline-flex;
      flex-direction: column;
      color: #f00;
    }

    .relative {
      position: relative;
      margin-left: 0;
    }

    label,
    input {
      width: 20em;
    }

    .mtb-ui-select {
      width: 20em;
      margin-left: 0;
      border-color: $btn-border-color;

      .mtb-ui-select-base {
        width: auto;
      }
    }

    input.readonlyInput {
      color: #888;
    }
    input:not(.mtb-ui-autocomplete-input),
    select {
      border: 1px solid $btn-border-color;
    }

    textarea.apiKeyPassword {
      font-size: 1em;
      color: #888;
      height: 15em;
      width: 100%;
      box-sizing: border-box;
    }
  }

  .modelUpload {
    input {
      opacity: 0;
      cursor: pointer;
    }

    .modelFile {
      display: none;
    }

    .fileOverlay {
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 3px;
      border: 1px solid $btn-border-color;
      padding: 0.5em;
      min-width: 20.5em;
      margin-left: 0;
      cursor: pointer;
      font-style: italic;
      height: 1.5em;
      overflow: hidden;
      white-space: nowrap;
      width: 20em;
    }

    .fileOverlay:active,
    .fileOverlay:focus {
      outline: 2px solid #000;
    }

    + .row > div {
      max-width: fit-content;
    }
  }

  .buttons {
    @extend .dialogButtons;
  }

  button[disabled] {
    cursor: default;
  }

  .copyButton {
    color: $btn-secondary-text-color;
    background-color: $btn-secondary-color;
    width: 12em !important;
    height: 3em;
    order: 1;
  }

  .linkButton {
    border: none;
    text-decoration: underline;
  }
}

.challengerInput {
  h3,
  div {
    margin-left: 0;
  }

  .formControl.challenger {
    label {
      font-size: 13px;
    }

    p {
      line-height: 1.5em;
      margin: 0;
    }

    .inputWithText {
      margin-top: 10px;

      ::placeholder {
        color: $default-text-color;
      }
    }

    label,
    input,
    select {
      display: block;
      width: auto;
      height: 1.5em;
    }

    input:not(.mtb-ui-autocomplete-input),
    .fileOverlay {
      border: 1px solid $btn-border-color;
      border-radius: 2px;
    }

    .mtb-ui-autocomplete-input-root,
    .mtb-ui-autocomplete-input-root:hover {
      border: 1px solid $btn-border-color;
      button {
        height: unset;
      }
    }

    .fileOverlay {
      width: 41.8em;
      margin-top: 10px;
    }

    .deployment-label {
      font-weight: 600;
    }

    .source-group {
      padding-top: 20px;

      label {
        margin-bottom: 10px;
      }

      .source-control {
        display: flex;
        flex-direction: row;
        min-width: 30%;

        .mtb-ui-radio-root {
          padding-right: 7px;
        }
      }
    }

    .mtb-ui-input.mtb-ui-input-base {
      margin-top: 10px;
      font-size: 13px;

      &:not(.mtb-ui-autocomplete-input-root) {
        border: none;
        border-radius: none;
      }

      &.mtb-ui-select {
        border: 1px solid $btn-border-color;
        border-radius: 2px;
      }

      .mtb-ui-list-item-text-root > span {
        font-size: 13px;
      }
    }

    .mtb-ui-form-label {
      margin-bottom: 0;
    }

    .mtb-ui-autocomplete-end-adornment {
      display: flex;
      top: unset;
    }
    .mtb-ui-input-base.mtb-ui-input-base-end-adornment {
      overflow: unset;
    }
  }

  .import-native {
    label {
      font-weight: 600;
      height: auto;
      color: #555 !important;
    }

    .formControl {
      &.challenger {
        display: flex;
        flex-direction: column;
        align-items: baseline;

        .mtb-ui-input-base.fit {
          min-width: unset;
          width: unset;
        }

        .mtb-ui-input-base,
        .inputWithText {
          min-width: 100%;
          width: 100%;
          margin-top: 5px;

          > div {
            width: 100%;
          }
        }
      }

      &.select {
        margin-bottom: 0.5em;
        + div {
          margin-bottom: 1em;
        }
      }

      &.row > label,
      &.row > input {
        width: -webkit-fill-available;
      }
    }

    .validationMessage {
      padding: 0 0 20px 0;
    }
  }

  .modelUpload {
    label {
      padding-bottom: 10px;
    }

    .overlay {
      display: flex;
      align-items: center;
      gap: 12px;
      height: 40px;

      .file {
        cursor: pointer;
        flex-grow: 1;
        height: 36px;
        line-height: 36px;
        min-width: 445px;
        background-color: #eee;
        border: 1px solid #ddd;
        font-style: normal;
      }

      input[type='file'] {
        visibility: hidden;
      }

      span {
        display: block;
        padding-left: 10px;
        color: $btn-secondary-text-color;
      }

      button {
        background-color: $btn-secondary-color;
        color: $btn-secondary-text-color;
        border-radius: 3px;
      }
    }
  }

  .alert {
    padding: 0 0 20px 0;
    margin: 0;
  }

  .button-row {
    display: flex;
    justify-content: space-between;
  }

  .buttons {
    padding-top: 0;

    &.create > .confirmButton {
      padding: 0 20px;
    }
  }
}

.mtb-ui-select,
.mtb-ui-select-base-menu {
  .listItemText > span {
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.flags {
  input {
    margin: 10px 10px 0;
  }
}

.languageSettings {
  .languageContent {
    div {
      margin-left: 0;
    }

    .locale {
      display: flex;
      flex-direction: row;

      .formControl {
        margin-right: 10px;
      }

      label,
      input {
        width: 15em;
      }

      .mtb-ui-select {
        width: 15em;

        .mtb-ui-select-base {
          width: 100%;
        }
      }
    }

    .examples {
      margin-top: 20px;

      .example {
        display: flex;
        flex-direction: row;
        margin-bottom: 0.75em;

        div {
          width: 15em;
        }
      }
    }
  }
}

.modalDialog {
  @extend .dialog;
  height: auto;
  max-height: 85%;
  width: 560px;
  overflow-y: auto;
}

.embedded {
  .header {
    position: absolute;
    height: 36px !important;
    width: calc(100% - 20px);
    top: 0;
    left: 0;
    z-index: 10001;
    padding: 10px;
  }

  .dialogContainer > .modalDialog {
    position: absolute;
    height: 100%;
    width: calc(100% - 40px);
    box-shadow: none !important;
    border: none;
    padding-top: 70px;
    left: 0;
    right: 0;
    top: 50px;

    input,
    #modelName,
    .inputWithText {
      min-width: 22em;
      width: 22em;
    }

    span {
      display: block;
      padding-bottom: 25px;
    }

    .validationMessage {
      padding: 1.5em 0;
    }
  }
}

.apiKeyCreateDialog {
  @extend .dialog;

  h4 {
    margin-bottom: 5px;
  }

  span {
    width: 70px;
  }

  input[type='checkbox'] {
    margin-right: 10px;
  }

  input {
    font-size: 13px !important;
    padding: 0.5em !important;
    height: 1rem !important;
  }

  input[type='text'],
  input[type='date'],
  .mtb-ui-text-field {
    flex-grow: 1;
    .mtb-ui-input-base {
      margin-left: 0;
    }
  }

  .MuiInputAdornment-root {
    svg {
      font-size: 1rem;
    }
  }

  .value-pair {
    display: flex;
    align-items: center;
    margin: 10px 0 20px 10px;

    + div.error {
      display: block;
      margin: -15px 0 15px 80px;
      color: $error-text-color;
      font-weight: 600;
      font-size: 0.9em;
    }
  }

  .scopes-label {
    font-style: italic;
  }

  .scopes {
    display: flex;
    margin: 20px 0 0 -5px;

    div {
      display: flex;
      align-items: center;
      padding: 0 20px 0 5px;
    }
  }

  input.error {
    border-color: $error-text-color;
  }

  .warning {
    display: flex;
    flex-direction: row;
    background-color: #ffd89e;
    padding: 20px;
    margin-bottom: 20px;

    svg {
      min-height: 24px;
      min-width: 24px;
      margin-right: 10px;
    }
  }
}

.uploadDialog {
  @extend .dialog;
  min-height: auto;
  width: $upload-dialog-width;
}

.upload {
  z-index: 1000;
  position: absolute;
  top: 10%;
  left: 50%;
  margin-left: calc(0px - #{$upload-dialog-width} / 2);
}

.browse {
  input {
    min-width: auto;
    opacity: 1;
    height: 2em;
  }

  .dataFileLabel {
    font-weight: bold;
    padding-bottom: 0.5em;
  }

  .uploadRow {
    bottom: 1em;
    left: 1em;
    right: 1em;
    display: flex;
    align-items: center;

    .dataFile {
      display: none;
    }

    .fileName {
      border: 2px solid $btn-border-color;
      width: 100%;
      padding: 5px 10px;
      border-radius: 2px;
    }

    .browseButton {
      cursor: pointer;
      min-width: 17.5%;
      border: 1px solid $btn-secondary-color;
      background-color: $btn-secondary-color;
      margin-left: 1em;
      padding: 5px 0;
      border-radius: 3px;
    }
  }
}

.errorList {
  height: 28em;
  overflow-y: auto;
  background-color: #fafafa;
  border: 1px solid #dddddd;
  border-radius: 3px;
  padding: 0 1em;

  .errorDetails:not(:last-child) {
    border-bottom: 1px solid #dddddd;
  }

  .errorDetails {
    padding-bottom: 1.5em;
    margin: 1.5em 0.5em;
    overflow-wrap: break-word;

    label {
      font-size: 15px;
      font-weight: $faux-bold-font-weight;
    }

    p {
      margin: 0.5em 0 0 0;
    }
  }
}

.modelDetails {
  .exampleNavigator {
    list-style-type: none;
    display: flex;
    justify-content: space-between;

    li {
      display: flex;
      gap: 6px;
    }

    button {
      border: 0;
      font-size: 1em;
      padding: 0.5em;
      cursor: pointer;
      vertical-align: 'center';
    }

    button.active {
      font-weight: bold;
      background-color: #eee;
    }

    button.clipboard {
      background-color: #fff;
      flex-grow: 1;
      padding: 0;
      display: inline;

      svg {
        height: 1em;
        margin-right: 0.25em;
      }
    }
  }

  .html {
    padding: 1em;
    border: 1px solid #c0c0c0;
    overflow-y: scroll;
    height: 22em;

    input[type='submit'] {
      min-width: 5em !important;
      background-color: rgb(9, 156, 9);
      color: #fff;
      cursor: pointer;
    }
  }

  .curl {
    display: flex;
    flex-direction: column;
    padding: 0 1em;
    padding-top: 0.5em;
    border: 1px solid #c0c0c0;
    height: 23em;

    h3.curl-header {
      display: flex;
      margin: 0;
      padding: 0;
      justify-content: space-between;
    }

    button.clipboard {
      background-color: #fff;
      border: 0;
      padding: 0;
      display: inline;

      svg {
        height: 1em;
        margin-right: 0.25em;
      }
    }

    .curlCode {
      flex: 1;
      font-family: 'Courier New', Courier, monospace;
      font-size: 1em;
      white-space: break-spaces;
      margin-bottom: 1em;
    }
  }

  .predictorsSchema {
    width: 46.75em;
    height: 20em;
  }

  .hiddenBuffer {
    position: absolute;
    left: -9999px;
  }
}

.confirmation {
  height: auto;
  width: 320px;
}

.addModel {
  .buttons {
    padding-top: 5%;
  }

  label,
  input {
    height: 1.5em;
  }

  .mtb-ui-autocomplete-input-root {
    button {
      height: unset;
    }
  }

  .mtb-ui-input.mtb-ui-input-base {
    margin-left: 0;
    margin-top: 10px;
    font-size: 13px;
    border: 1px solid $btn-border-color;
    border-radius: 2px;

    &:hover {
      border: 1px solid $btn-border-color !important;
    }
  }

  .mtb-ui-form-label {
    margin-bottom: 0;
  }

  .mtb-ui-autocomplete-end-adornment {
    top: unset !important;
  }
}

.validationMessage {
  color: $error-text-color;
  font-size: 12px;
  font-weight: 600;
  padding: 20px 0 0 0;
}

.email-info {
  padding: 20px 0 10px 0;
  font-size: 12px;

  span {
    font-weight: 600;
  }
}
