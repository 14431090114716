@import 'variables';

.table {
  display: flex;
  flex-direction: column;

  .header-row,
  .data-row {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #eee;
    height: 3em;
    align-items: center;
    max-width: calc(100vw - 100px);

    &.no-border {
      border-bottom: none !important;
    }

    div:not(.entries-text) {
      white-space: pre;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 150px;
      padding: 0 5px;

      /* flex-shrink: 0; */

      i.green {
        color: green;
      }
      i.blue {
        color: blue;
      }
      i.red {
        color: red;
      }
      i.yellow {
        color: orange;
      }
      i.grey {
        color: #888;
      }
    }

    div.extraWideCell {
      width: 270px;
    }
    div.wideCell {
      width: 185px;
      min-height: 18px;
    }
    div.mediumCell {
      width: 130px;
    }
    div.smallCell {
      width: 75px;
      min-width: 75px;
    }
    div.hiddenCell {
      width: 2em;
      min-width: 2em;
      display: flex;
      flex-direction: row;
      justify-content: center;

      svg {
        width: 1.5em;
        text-align: center;
        margin-left: 0;
        line-height: 2em;
      }
    }

    .align-right {
      text-align: right;
      padding-right: 5em;
      margin-left: 0 !important;
    }

    div.fill {
      flex-grow: 1;
    }
  }

  .header-row {
    color: #888;
    font-weight: bold;

    div span {
      font-size: 0.875em;
    }
  }

  .header-row.report-header {
    color: #555;
    font-size: 1em;
  }

  .data-row {
    position: relative;

    .alertIcon {
      width: 1.1em;
    }
  }

  .data-row:hover {
    background-color: #f8f8f8;

    .actionLink {
      visibility: visible;
    }
  }

  .data-details-row {
    height: 15em;
    margin: 1em;
    padding: 0.5em;
    word-break: break-all;
    overflow-y: auto;
    border: 2px solid #ddd;
    border-radius: 4px;
  }
}

.gridIcon {
  width: 1.5em;
  text-align: center;
  margin-left: 0;
  line-height: 2em;
}

div.queued,
div.pending,
div.deleting,
div.destroying,
div.uploading {
  font-style: italic;
}
div.failed,
div.uploadFailed {
  color: $error-text-color;
}

div.driftCell {
  display: flex;
  align-items: center;
  justify-content: center;
  div.text {
    color: darkgray;
  }
}

.overall-drift {
  display: flex;
  align-items: flex-end;
  gap: 5px;
  svg {
    flex-shrink: 0;
    width: 16px;
    height: 16px;
  }
}

div.link {
  cursor: pointer;

  &:hover {
    color: $link-text-color;
  }
}

.actionLinkItem {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2em;
  min-width: 2em;
  padding: 0;

  &.disabled {
    cursor: auto;
  }

  svg {
    min-width: 1.5em;
    max-width: 1.5em;
    min-height: 1.5em;
    max-height: 1.5em;
    padding: 0.33em;

    &.disabled path {
      filter: opacity(50%) contrast(15%);
    }

    &:hover:not(.disabled) {
      background-color: #dadada;
      border-radius: 3px;
    }
  }
}

.actionLink {
  font-size: 1em;
  visibility: hidden;
  text-align: right;
  top: 0.25em;
  bottom: 0;
  right: 1em;
  color: #000;
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.alertItem {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 15px;
  width: 1.2em;
  min-width: 1.2em;

  svg {
    min-width: 1.2em !important;
    width: 1.2em !important;
  }
}

.sortIcon {
  width: 0.7em;
  padding-right: 0.5em;
}

.sortable:hover {
  text-decoration: underline;
  cursor: pointer;
}
