* {
  margin: 0;
  padding: 0;
  font-family: 'Segoe UI', Frutiger, 'Frutiger Linotype', 'Dejavu Sans', 'Helvetica Neue', Arial, sans-serif;
  box-sizing: content-box;
}
html,
body {
  height: 100%;
  overflow-y: hidden;
}
html,
body,
input,
textarea,
button {
  color: #333;
  background-color: #fff;
}
input[type='text'],
input[type='number'],
input[type='date'],
input[type='file'],
textarea {
  border: 1px solid #c0c0c0;
  border-radius: 5px;
  padding: 0.5em;
  min-width: 15em;
  resize: none;
}
input[type='checkbox'] {
  cursor: pointer;
}
input:read-only,
input:disabled {
  cursor: default;
  user-select: none;
  outline: none;
}
select {
  border: 1px solid #c0c0c0;
  border-radius: 5px;
  min-width: 15em;
  padding: 0.5em;
}
input[type='file'] {
  border: none;
}
body {
  font-size: 13px;
}
h1,
h2,
h3 {
  font-weight: 100;
  color: #555;
}

#root {
  height: 100%;
}
