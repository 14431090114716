@import 'variables';

.performance-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  color: #555;

  .drift-report .report-dashboard-container .icon-container {
    min-width: fit-content;
    width: 20px;
    margin-left: 0;
    padding: 0;

    .statusIcon {
      width: 1.5em;
    }
  }

  .report-header {
    padding-top: 15px;

    .timezone-labels {
      display: flex;
      padding-top: 12px;
    }

    .info {
      padding: 0 0.75rem 0.5rem 0.75rem;
      margin-right: 1%;
      display: flex;
      align-items: center;
      font-size: 13px;

      .icon {
        margin: 0 0.5em;
        width: 1.5em;
        height: 1.5em;
      }
    }

    .flex-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    .icon-container {
      margin: 5px 5px 0 5px;
    }

    .date-selector-container {
      padding: 12px 0;

      .period {
        padding-left: 12px;

        label,
        div {
          font-size: 13px;
          font-weight: normal;
        }
      }

      .date-range {
        display: grid;
        padding: 0 30px;
        .date-picker {
          grid-template-columns: subgrid;
          display: grid;
          grid-column: 1 / 5;
        }

        #localStart {
          grid-column-start: 2;
        }

        #localEnd {
          grid-column-start: 4;
        }
      }

      .button {
        min-width: 100px;
        margin-right: 8px;
        font-weight: normal;
        border-radius: 3px;

        &.secondary {
          background-color: $btn-secondary-color;
          color: $btn-secondary-text-color;
        }
      }

      .mtb-ui-input-base,
      .MuiInputBase-root {
        background-color: $input-background-color;
        height: 40px;
        width: 180px;

        &:hover {
          border-color: #efefef;
        }
      }

      &.pending-update {
        background-color: $btn-border-color;
        padding-right: 7px;

        fieldset,
        .mtb-ui-input-base {
          border: 1px solid $input-background-color;
        }
      }

      div {
        border-radius: 0;
      }
    }

    .button-container {
      display: flex;
      padding-top: 12px;
      flex-direction: row;

      .refresh-button-container {
        display: flex;
        align-items: center;
        margin-right: 5px;

        .refresh {
          background-color: $warning-color;
          color: #ffffff;

          & > * {
            padding: 5px;
          }
        }

        .report-header-status {
          display: flex;
          align-items: flex-end;
          white-space: nowrap;

          span {
            font-weight: $bold-font-weight;
            padding: 0 5px 0 8px;
          }

          &.not-current {
            color: $report-not-current-color;

            svg path {
              fill: $report-not-current-color !important;
            }
          }
        }
      }

      .report-button-container {
        display: flex;
        align-items: center;

        button {
          width: 32px;
          height: 32px;

          svg {
            width: 16px;
            height: 16px;
          }
        }
      }

      button {
        margin-left: 10px;
      }
    }

    .validationMessage {
      padding: 10px 0;
      flex: 0 1 100%;
    }
  }

  .report-dashboard-container,
  .drift-report,
  .stability-report {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .drift-report {
    .production-data {
      padding: 15px 0;

      .period-label-and-toggle-buttons {
        display: flex;
        align-items: center;

        label {
          font-size: 14px;
          font-weight: 600;
        }

        .model-name {
          font-size: 11px;
          margin-left: 8px;
        }

        .nav-buttons {
          display: flex;
          margin-left: 24px;
          gap: 8px;

          button {
            height: 32px;
            min-width: 89px;
            background-color: $btn-border-color;
            color: $btn-secondary-text-color;
            font-size: 13px !important;
            font-weight: normal;
          }
        }
      }
    }

    .no-data-message,
    .validationMessage {
      padding: 0;
      margin-top: 15px;
    }

    .data-row > .status {
      justify-content: flex-start;

      .no-status {
        padding: 0;
      }
    }

    .no-data {
      font-size: 0.9em;
      padding: 0;
    }
  }

  .stability-report {
    min-width: -webkit-fill-available;

    .metric-settings {
      display: flex;
      gap: 50px;
      padding: 25px 10px;

      .control {
        display: flex;
        flex-direction: row;
        align-items: center;

        label {
          font-weight: normal;
          margin-right: 15px;
        }

        .disabled {
          color: #ccc;
        }

        .mtb-ui-input-base {
          min-width: max-content;
          border-radius: 0;
          border-color: #efefef;
        }

        .mtb-ui-checkbox-root {
          color: $btn-primary-color;
        }
      }
    }
  }

  .empty-report {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $watermark-background-color;
    margin: 10px 0;
    padding: 15px;
    height: 100%;
    min-width: fit-content;

    svg {
      width: 55px;
      height: 55px;
    }

    .watermark {
      display: flex;
      flex-direction: column;
      padding: 0 0 5px 20px;
      color: $watermark-text-color;

      .title {
        font-size: 25px;
        font-weight: 600;
      }

      .description {
        font-size: 17px;
        max-width: 650px;

        a {
          color: $watermark-text-color;
        }
      }
    }
  }

  .loading-wrapper {
    z-index: 1;
  }
}
