@import 'variables';

.prototype {
  .start-of-day {
    display: flex;
    flex-direction: row;

    .select {
      margin-left: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;

      .timezone {
        min-width: 300px;
      }

      label {
        margin-top: 5px;
      }
    }
  }

  .date-selector-container {
    padding: 20px 0 15px;

    .period {
      display: none;
    }

    .date-range {
      display: grid;
      padding: 0 30px;

      label {
        align-self: center;
      }

      .date-picker {
        grid-template-columns: subgrid;
        display: grid;
        grid-column: 1 / 5;
      }

      #localStart {
        grid-column-start: 2;
      }

      #localEnd {
        grid-column-start: 4;
      }
    }
  }

  .stats {
    margin: 30px 20px;
    font-weight: 600;
  }

  .mtb-ui-input-base {
    border-color: $btn-border-color;
  }
}
