.filter {
  padding: .5em .75em 1.5em;
  display: flex;
  align-items:center;

  > svg {
    min-width: 32px;
    min-height: 32px;
  }
  
  .mtb-ui-select {
    margin-left: 10px;
  }
}