@import '~@mtb/ui/stylesheets/main.scss';
@import 'dialogs.scss';
@import 'variables';

.settings {
  max-width: 650px;
  padding: 10px;
  font-size: 13px;

  .LocalizedInput.mtb-ui-input.mtb-ui-input-base:not(.mtb-ui-input-base-error) {
    border: unset;

    &.mtb-ui-input-base-focused {
      outline: 2px solid black;
    }
  }

  .button-group {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    padding: 10px 0;
  }

  .emphasize {
    font-style: italic;
  }

  h4 {
    font-size: 14px;
    font-weight: 600;
    margin: 10px 0;
  }

  label,
  input,
  select,
  .error-info,
  .mtb-ui-input-base {
    font-size: 12px;
  }

  .error-info {
    padding: 5px 0;
    color: $error-text-color;
    flex-basis: 100%;
  }

  .section {
    padding-bottom: 15px;

    input,
    select {
      border: 1px solid $btn-border-color;
      border-radius: 5px;
      padding: 5px 10px;

      &.error {
        border-color: $error-text-color;
      }
    }

    .period-label {
      font-weight: normal;
    }

    .period-select > div {
      padding: 4px 10px;
    }

    .label-input {
      display: flex;
      align-items: baseline;
    }

    &:not(.wide) label {
      display: inline-block;
      padding-right: 10px;
    }

    select {
      min-width: 12em;
    }

    input:disabled::placeholder {
      color: #929292;
    }

    input:focus::placeholder {
      opacity: 0;
    }

    input::placeholder {
      color: $default-text-color;
    }

    select,
    input:not(.checkbox) {
      height: 1.8em;
    }

    .section-instructions {
      font-size: 12px;
      margin-bottom: 10px;
    }

    .checkbox {
      margin-right: 10px;
    }

    .inline-checkbox {
      display: inline-flex;
      align-items: center;
      margin-top: 10px;

      &.require-id-var {
        margin-bottom: 10px;
      }

      label {
        min-width: 250px;
      }
    }

    .monitor-select {
      display: flex;
      align-items: center;
      margin-top: 10px;
      label {
        display: block;
        width: 125px;
      }
    }

    .drift-inline {
      display: inline-flex;
      flex: 0 1 auto;
      align-items: center;
      justify-content: center;
      margin: 5px 0;

      label {
        min-width: 100px;
        max-width: 100px;
      }

      input:not(.mtb-ui-input-base-input),
      .mtb-ui-input-base {
        min-width: 95px;
        max-width: 95px;
        margin-left: 10px;
      }
    }

    .overall-drift-criteria {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &.moderate {
        margin-top: 15px;
      }

      label {
        font-weight: 600;
      }

      .criteria-inline {
        display: inline-flex;
        align-items: center;
        margin-top: 10px;

        label {
          font-weight: normal;
          margin-top: -0.05em;
        }

        input[type='number'] {
          min-width: 40px;
          width: 40px;
          margin: 0 10px 0 0;
        }
      }
    }

    .start-of-day {
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;

      .select {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;

        .timezone {
          min-width: 250px;
          margin-right: 20px;
        }

        .mtb-ui-input-base.mtb-ui-select {
          border-color: $btn-border-color;
          height: 2.4rem;
        }
      }
    }
  }

  .buttons {
    @extend .dialogButtons;
    gap: 8px;

    &.left {
      position: inherit;
      margin-top: 20px;
      flex-direction: row;
      left: 0em;
    }

    .apply {
      background-color: #5192ed;
      margin-left: 0;
      padding: 0;

      &:hover {
        cursor: pointer;
      }

      :disabled {
        cursor: default;
      }
    }
  }

  button {
    background-color: $btn-primary-color;
    font-weight: 400;
    padding: 0 24px;
    height: 35px;

    &.cancel {
      color: black;
      background-color: $btn-secondary-color;
    }
  }

  .email-alerts-header {
    margin-top: 20px;
  }

  .email-alerts {
    width: 400px;
    font-size: 0.9em;
    border-radius: 0.3rem;
    margin-top: 10px;

    .chip-spacing {
      margin-top: 20px;
    }

    .email-chip {
      display: inline-flex;
      align-items: center;
      background-color: #eee;
      border-radius: 30px;
      height: 30px;
      padding: 0 4px 0 1rem;
      margin: 0 0.3rem 0.3rem 0;

      .remove-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        border: none;
        cursor: pointer;
        font: inherit;
        margin-left: 10px;
        font-weight: bold;
        padding: 0;
        line-height: 1;
      }
    }

    .email-input {
      display: flex;
      align-items: baseline;
      gap: 8px;

      .input {
        width: 100%;
        height: 40px;
        padding: 0 1rem;
        margin-top: 5px;
        box-sizing: border-box;
        font: inherit;
        border-radius: 5px;
        border: 1px solid #eee;
        color: #565656;
        -webkit-appearance: none;

        &:focus {
          border-color: #568ccb;
          outline: none;
        }

        &.has-error {
          border-color: $error-text-color;
        }
      }
    }

    .error {
      padding: 5px 0;
      font-size: 12px;
      color: $error-text-color;
    }
  }
}
