$btn-primary-color: #5192ed;
$btn-secondary-color: #ddd;
$btn-secondary-text-color: #1e1f20;
$btn-disabled-color: #ddd;
$btn-disabled-text-color: #fff;
$btn-enabled-text-color: #fff;
$default-text-color: #333;
$input-background-color: #fff;
$btn-border-color: #eee;
$table-cell-background-color: #f8f8f8;
$mmops-logo-color: #cc0e49;
$error-text-color: #e66c6d;
$link-text-color: #1767a6;
$active-color: #2196f3;
$warning-color: #e56768;
$report-not-current-color: #c64541;
$watermark-background-color: #f3f5f7;
$watermark-text-color: rgba(81, 146, 237, 0.5);

$upload-dialog-width: 450px;

$dialog-z-index: 10000;

$faux-bold-font-weight: 500;
$bold-font-weight: 600;
