@import 'variables';

.header {
  background-color: #293b51;
  height: 56px;
  display: flex;
  flex-shrink: 0;
  padding-right: 20px;

  .logo {
    width: 56px;
    height: 100%;
    margin-right: 1em;
  }

  .title {
    display: inline-flex;
    margin: auto;
    flex-grow: 1;

    h1 {
      color: #ffffff;
      font-size: 1.461em;
      font-weight: 400;

      sup {
        font-size: 0.875rem;
      }
    }
  }

  .status-tray {
    color: #fff;
    font-size: 1.1em;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    padding: 0.7em;

    svg {
      height: 2em;
      fill: #fff;
      padding-right: 0.7em;
    }
  }

  .header-tool {
    cursor: pointer;
    color: #fff;
    height: 20px;
    width: 20px;
    align-self: center;
    margin: 0 1.2em;

    &.settings-icon {
      .context-menu {
        width: 250px;
        margin: 5px 100px 0 0;
      }
    }

    &.user-icon {
      height: 25px;
      width: 25px;

      .context-menu {
        margin: 5px 50px 0 0;
      }
    }

    .context-menu {
      .name {
        font-size: 16px;
        padding-bottom: 0;
      }

      .email {
        font-size: 11px;
        padding-top: 0;
        padding-bottom: 1em;
      }

      .link {
        font-size: 13px;
        color: $link-text-color;
        cursor: pointer;

        a {
          color: $link-text-color;
          text-decoration: none;
          font-weight: normal;
        }
      }

      div {
        cursor: default;

        &:hover {
          background-color: unset;
        }
      }
    }
  }
}

.report-header {
  .period-selector {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;

    .period-label-and-toggle-buttons {
      display: flex;
      align-items: center;

      .nav-buttons {
        margin-left: 10px;
        margin-top: 5px;
      }

      svg.nav-icon {
        width: 15px;
        margin-left: 15px;
      }
    }

    .period {
      justify-content: right;
    }
  }

  .date-selector-container {
    display: flex;
    align-items: flex-start;

    label {
      padding-right: 12px;
      align-self: center;
    }

    .date-selector {
      min-width: 150px;
      padding-right: 15px;

      .MuiOutlinedInput-notchedOutline {
        border-color: #eee;
      }

      input {
        height: 2em;
        padding: 5px 12px;
        font-size: 0.8em;
        width: 120px;
      }
    }

    .period {
      padding: 0 25px 0 25px;
    }

    .button {
      color: white;
      background-color: #1c7ec7;
      font-size: 13px;
      height: 3em;
      min-width: 11em;
      white-space: normal;
    }
  }
}
