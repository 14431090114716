@import 'variables';

.importBaselineData {
  color: $btn-secondary-text-color;
  display: flex;
  flex-direction: column;

  label {
    font-weight: $faux-bold-font-weight;
  }

  .model-input {
    margin-bottom: 2em;
  }
  .file-input-container {
    margin-left: 0;
    margin-bottom: 3em;

    .validationMessage {
      padding: 0;
      padding-top: 0.75em;
    }
  }

  .model-input,
  .browse {
    display: flex;
    flex-direction: column;

    .file-label,
    .dataFile {
      padding-bottom: 0.5em;
    }

    .model-name,
    .fileName {
      border-radius: 2px;
      background-color: $btn-border-color;
      height: 1.75em;
      padding: 0.5em;
      border: 1px solid $btn-disabled-color;
    }
  }

  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .baseline-data-table-container {
    padding-left: 10px;
    margin-left: 0;
    box-sizing: border-box;
    max-height: 25em;
    min-height: 15em;

    .baseline-data-table {
      border-collapse: collapse;
      table-layout: fixed;
      width: 100%;

      .column-header {
        font-weight: normal;
        background: unset;
        padding: 0;
      }

      .row-header-cell {
        background-color: $btn-border-color;
        padding: 0 1em;
        border-left: 1px solid $btn-disabled-color;
        word-wrap: break-word;
        word-break: break-all;
        width: 50%;
        min-width: 0;

        .row-header-content {
          margin: unset;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .text {
            margin: unset;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .warning {
            fill: $warning-color;
            min-width: 1.25em;
            max-width: 1.25em;
            min-height: 1.25em;
            max-height: 1.25em;
          }
        }
      }

      .row-input {
        padding: 0;
        border-right: 1px solid $btn-disabled-color;
        border-left: 1px solid $btn-disabled-color;

        .unselected-field > .mtb-ui-input-base > .mtb-ui-input-base-input {
          // The extra 2 digits are opacity (50%)
          color: #{$btn-secondary-text-color}7f;
          & > em {
            font-style: normal;
          }
        }
      }

      .mtb-ui-table-cell-root {
        border-bottom: 1px solid $btn-disabled-color;

        .mtb-ui-input-base {
          margin: unset;
          border: unset;
          max-width: unset;
        }
      }
    }
  }
}
